// body {
//   background: rgb(0, 79, 212);
//   background: radial-gradient(
//     circle,
//     rgba(0, 79, 212, 1) 0%,
//     rgba(69, 0, 106, 1) 100%
//   );
//   background-image: url(../../images/bg-deep.png);
//   background-repeat: no-repeat;
//   background-size: cover;
//   min-height: 100%;
// }
// body.on-boarding {
//   background-image: url(../../images/bg-on-boarding.png);
// }
body {
  background: rgb(0, 79, 212);
  background: radial-gradient(
    circle,
    rgba(0, 79, 212, 1) 0%,
    rgba(69, 0, 106, 1) 130%
  );
  @media (min-width: 576px) {
    // TODO: DESKTOP
    // background: #e4e4e4 !important;
  }
}

html,
body {
  height: 100%;
}

#root {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(0, 79, 212);
  background: radial-gradient(
    circle,
    rgba(0, 79, 212, 1) 0%,
    rgba(69, 0, 106, 1) 130%
  );

  @media (min-width: 576px) {
    background: transparent !important;
    position: relative;
    max-width: 411px;
    max-height: 736px;
    margin: 0 auto;
  }
  // display: flex;
  // justify-content: center;
  // overflow: auto;
}
#page {
  padding: 25px 15px 0;
  height: 100%;
  position: relative;
  z-index: 1;
  // width: 100%;

  @media (min-width: 576px) {
    overflow-x: scroll;
    max-width: 411px;
    max-height: 736px;
  }
}
body.on-board #page {
  overflow: hidden;
}

#ProfilePageAvatar {
  background-image: url('../../images/avatar.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

img.bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.12;

  /* Preserve aspect ratio */
  min-width: 100%;
  min-height: 100%;
  // display: none;

  @media (min-width: 576px) {
    position: absolute;
    display: none;
  }
}

img.bg.christmas {
  opacity: 1;

  /* Preserve aspect ratio */
  width: 100%;
  min-height: 100%;
  // display: none;

  @media (min-width: 576px) {
    position: absolute;
    display: none;
  }
}

p,
.p-typography {
  color: #fff;
  font-size: 18px;
}

.onboard__container {
  padding: 0;

  #join__element {
    .inner {
      padding-left: 5px;
      padding-right: 5px;
    }
    .ui.input {
      margin-bottom: 25px;
    }
    #joinButton {
      display: none;

      &.entered {
        display: block;
      }
    }
  }

  #slider__element {
    .image-wrapper {
      min-height: 300px;
    }
  }
}

/** OTP **/
.resend {
  color: #ffc800;
  font-family: $font-family-titanOne;
  display: inline-block;
  margin-left: 12px;

  &.counting {
    color: #ced2d5;

    @include media-breakpoint-down(s) {
      font-size: 16px;
    }
  }
}

button.ui.button.resend {
  color: #ffc800;
  background-color: transparent;
  display: inline-block;
  width: auto;
  padding: 0;
  height: 20px;
  margin-left: 12px;
  font-size: 18px;

  &::after,
  &::before {
    width: 0.875em;
    height: 0.875em;
    margin-top: -0.35em;
    border-width: 0.14em;
  }
}

.page-control {
  margin-bottom: 20px;
}

.player-meta {
  .profile-summary {
    position: relative;
    padding-left: 30px;

    .blue__box {
      padding-left: 30px;
      padding-top: 5px;
    }

    .image {
      border-radius: 50%;
      height: 52px;
      width: 52px;
      position: absolute;
      background: #004fd4;
      top: -5px;
      left: 0;
      // border: 3px solid #fff;
      background-size: contain;

      .circle__spin {
        position: absolute;
        left: -5px;
        top: -5px;
        width: 62px;
        height: 62px;
      }
      .circle__spin svg {
        width: 100%;
        height: 100%;
      }

      .circle__spin circle {
        stroke-width: 6; /* control the dashes width */
        stroke-dasharray: 100, 20; /* control the dashes spacing */
        fill: none;
        stroke: #fff;
      }

      .circle__spin svg {
        animation: spin 10s linear infinite;
      }

      @keyframes spin {
        100% {
          transform: rotateZ(360deg);
        }
      }
    }

    .rank,
    points {
      display: block;
    }

    .rank {
      font-weight: normal;
      position: relative;
      font-size: 9px;
      padding-bottom: 2px;

      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        height: 1px;
        width: 75%;
        background: rgba(25, 255, 255, 0.2);
      }
    }
  }

  .riskIt {
    .blue__box {
      position: relative;
      padding-left: 30px;

      .desc {
        position: absolute;
        bottom: -12px;
        right: 0;

        .blue__box {
          height: auto;
          padding: 2px 5px 2px 15px;
        }
      }
    }

    .keys {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;

      .key-item {
        width: 20px;
        height: 20px;
        margin-right: 3px;
      }
    }
  }

  .blue__box {
    background-color: #004fd4;
    width: 100%;
    height: 20px;
    border-radius: 5px;
    padding: 1px 5px;
    height: 100%;
    color: #fff;
    height: 40px;
    font-size: 11px;
    font-weight: bold;
  }
}

.adv__box {
  .object {
    height: 190px;
    width: 100%;
    overflow: hidden;
  }
}

.playButton {
  .playButtonWrapper {
    width: 156px;
  }
}

.page-desc {
  text-align: center;
  margin: 0 auto;
  max-width: 184px;
  margin-bottom: 10px;

  p {
    font-size: 1rem;
  }
}

.game-select {
  margin-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;

  .col-4 {
    flex: 0 0 37.4999999%;
    max-width: 37.4999999%;

    @media (max-width: 360px) {
      flex: 0 0 39.4999999%;
      max-width: 39.4999999%;
    }
  }

  .game-item {
    color: #fff;
    background-color: rgba(5, 22, 87, 0.4);
    border-radius: 4px;
    padding: 30px 16px 15px;
    height: 100%;
    display: flex;
    flex-flow: column;
    box-shadow: inset 0px 8px 48px rgba(0, 0, 0, 0.1);

    @media (max-width: 375px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &.no-risk-it {
      opacity: 0.25;
    }

    h3 {
      margin-bottom: 8px;
      font-size: 1.125rem;
      font-weight: bold;
    }

    .object {
      max-width: 70px;
      margin-bottom: 20px;
      display: block;

      .inner {
        height: 71px;
        position: relative;

        .media-object {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &.svg svg {
        max-width: 55px;
        height: auto;
      }

      &.qod img {
        width: 41px;
        height: auto;
      }
    }

    .content {
      font-size: 12px;
      line-height: 1.143rem;
      margin-bottom: 15px;
    }

    .extra {
      display: flex;
      height: 100%;
      align-items: flex-end;
    }

    .play {
      color: $highlight;
      font-family: $font-family-titanOne;
      font-size: 0.875rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: -13px;
        bottom: 4px;
        width: 5.8px;
        height: 9.42px;
        background-image: url(../../images/back-button.svg);
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(180deg);
      }
    }
  }
  .game-item.adv {
    padding: 0;
    overflow: hidden;
    position: relative;

    .extra {
      position: absolute;
      bottom: 15px;
      left: 16px;
    }
  }
}

.page-control-points {
  display: flex;

  &.right {
    justify-content: flex-end;
  }

  .points-wrapper {
    // background-color: $blue;
    display: inline-block;
    padding-left: 10px;
    overflow: hidden;
    line-height: 1;

    .inner {
      background-color: #fff;
      width: 100%;
      padding: 0 6px 0 20px;
      height: 25px;
      border: 3px solid $blue;
      border-radius: 13px;
      display: flex;
      align-items: center;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 5px;
        height: 100%;
        background-color: $blue;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }

    .points {
      font-size: 12px;
      font-weight: bold;
      margin-top: 2px;
    }

    .icon {
      position: absolute;
      left: -4px;
      top: -1px;
    }
  }
}

.AreYouReady {
  .object {
    img {
      margin: 0 auto;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 28px;
  }
}

#Timer {
  width: 30px;
  height: 30px;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -4px;
    left: 0;
  }

  svg#circle {
    transform: rotateY(-180deg) rotateZ(-90deg);

    circle {
      stroke-dasharray: 500px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 14px;
      stroke: #00a0f5;
      fill: none;
      animation: countdown linear forwards;
    }
  }

  #secondHand {
    transform-origin: 50% 50%;
    animation: animate-seconds linear;
  }

  @keyframes animate-seconds {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes countdown {
    from {
      stroke-dashoffset: 0px;
    }
    to {
      stroke-dashoffset: 500px;
    }
  }
}

.ui.dimmer.popeye {
  position: fixed;
  padding-top: 25px;
  background-color: rgb(0, 79, 212) !important;
  background: radial-gradient(
    circle,
    rgba(0, 79, 212, 1) 0%,
    rgba(69, 0, 106, 1) 100%
  ) !important;
  background-image: url(../../images/bg-deep.png) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

  &.cover {
    background-image: none !important;
    background: linear-gradient(
      -45deg,
      #00317c,
      #45006a,
      #004fd4,
      #0048b8
    ) !important;
    // background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 350% 350% !important;
    animation: gradientBG 5s ease infinite !important;

    svg {
      width: 180px;
    }
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.blinking-cursor {
  position: absolute;
  width: 1px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 16px;

  &::after {
    width: 1px;
    height: 16px;
    background-color: #fff;
    animation: 1s blink step-end infinite;
    content: '';
    display: block;
  }
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* style.css*/
/* ----------------------------------------------
 * Generated by Animista on 2019-12-1 18:23:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes slideIn {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideIn {
  animation-name: slideIn;
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-1000px);
    opacity: 0;
  }
}

.slideOut {
  animation-name: slideOut;
}

/* Not needed with the cssTransition helper */

.animate {
  animation-duration: 800ms;
}

.campaignCountDownWrapper {
  position: absolute;
  bottom: 0;
}
#campaignCountDown {
  display: flex;
  justify-content: space-between;
  width: 165px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 18px;

    > span {
      font-size: 10px;
    }
  }
}

/**
 * Error Boundary 
 */
#errorBoundary {
  .img {
    width: 320px;
    height: 190px;
    background-image: url(../../images/ErrorBoundaryImage.png);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .content {
    width: 80%;
    margin-top: 25px;

    h1 {
      color: #fff;
      font-size: 20px;
      font-weight: 900;
    }

    p {
      font-size: 1rem;
    }

    ul {
      li {
        color: #fff;
      }
    }
  }
}

.downloadBtn {
  width: 50%;
  height: 57px;
  background-image: url('../../images/downloadBtn.png');
  background-repeat: no-repeat;
  background-size: 100% 200%;

  &.ios {
    background-position: bottom;
  }
}
