.modal.fade.in .modal-body {
  bottom: 0;
  opacity: 1;
}
.modal-body {
  position: absolute;
  bottom: -250px;
  right: 0;
  padding: 30px 20px 15px;
  width: 100%;
  height: 250px;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  opacity: 0;
  -webkit-transition-delay: 500ms;
  -moz-transition-delay: 500ms;
  -o-transition-delay: 500ms;
  transition-delay: 500ms;
  -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  transition: opacity 0.3s ease-out, bottom 0.3s ease-out;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0px -8px 12px -4px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px -8px 12px -4px rgba(0, 0, 0, 1);
    box-shadow: 0px -8px 12px -4px rgba(0, 0, 0, 1);
  }
}
.modal-body p {
  color: #000;
}
.close {
  margin-top: -20px;
  text-shadow: 0 1px 0 #ffffff;
}
.popup-button {
  margin-left: 140px;
  margin-top: 77px;
  font-weight: bold;
}

.usdd-icon {
  width: 128px;
  margin-bottom: 20px;

  svg {
    width: 100%;
    height: auto;
  }
}

.ModalBackdrop {
  z-index: 1;
}
.BottomModal {
  &.wide {
    padding-top: 15px;
    width: 100%;
  }
}

.app-adv {
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-top: 25px !important;
  padding-bottom: 10px !important;
  background: #031bd6; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0112a0,
    #031bd6
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0112a0,
    #031bd6
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .content {
    position: relative;

    .close-link {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);

      p {
        margin-bottom: 0;
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
  .info {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }

  .ourImage {
    .ml-auto {
      margin-bottom: 20px;
    }

    .object {
      position: absolute;
      right: -50px;
      // top: -115px;
      bottom: -20px;
      z-index: -1;
    }
  }

  .action {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 10px;

    .dwnl-btn {
      padding: 0 15px;
      display: block;
      outline: none;
    }
  }
}

.video-adv {
  width: 100%;
  height: 360px;

  .videoWrapper {
    position: relative;

    .react-player {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  h1 {
    position: absolute;
    top: -60px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: $font-family-titanOne;
  }

  .close {
    position: absolute;
    bottom: -60px;
    width: 100%;
    text-align: center;

    p {
      font-size: 1rem;
      color: #fff;
      font-weight: 600;
    }
  }
}
