.slick-dots li.slick-active button:before {
  color: #fff;
  opacity: 0.75;
}

.slick-dots li button:before {
  color: #fff;
  font-size: 10px;
}

.gameDeck {
  margin-left: -16px;
  margin-right: -16px;
  overflow: hidden;

  .slick-list {
    padding: 25px 12% !important;
  }
  .slick-slide {
    transition: all ease-in-out 3ms;
  }
  .slick-slide.slick-active > div > div > div {
    // box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    // transform: scale(1.052);
  }
}
