.Toastify__toast-container {
  padding: 10px 10px 0;
}

.Toastify__toast {
  margin-bottom: 0;
  border-radius: 2px;
  padding-right: 16px;
  padding-left: 16px;

  .Toastify__close-button {
    align-self: center;
  }
}

.download-notify {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 16px;

  .inner {
    background-color: #fff;
    border-radius: 0 0 6px 6px;
    padding: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  p {
    color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }

  .action {
    display: flex;
  }
}
